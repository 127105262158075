<template>
  <div class="w-full">
    <TotalComplaints :total="count" title="Total conciliaciones y demandas" body="solicitudes registradas" />
    <header class="
      mb-4
      flex flex-col-reverse
      items-center
      justify-center
      lg:flex-row lg:justify-between
      gap-3
      px-4
    ">
      <BaseDate id="date-input" label="Seleccione fecha" type="text" placeholder="AAAA/MM/DD" class="mb-3 w-1/2"
        v-model="date" :disabled-date="no_hoy_ni_despues" @input="dateChanged" :allowClear="false"></BaseDate>
      <a-dropdown class="w-64 mt-7">
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="xlsx">
              <a-icon type="upload" :style="{ fontSize: '16px' }" class="mr-2" />
              .xlsx
            </a-menu-item>
            <a-menu-item key="csv">
              <a-icon type="upload" :style="{ fontSize: '16px' }" class="mr-2" />
              .csv (Separado por comas)
            </a-menu-item>
            <a-menu-item key="txt">
              <a-icon type="upload" :style="{ fontSize: '16px' }" class="mr-2" />
              .txt
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary" shape="round">
          Generar información para SOLIP
          <a-icon type="down" />
        </a-button>
      </a-dropdown>
      <a-button shape="round" class="mt-7" @click="visibleDrawer = !visibleDrawer">
        Descargar archivos (.zip)
        <a-icon type="file-zip" />
      </a-button>

      <div class="w-full md:max-w-sm grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">

        <a-drawer :bodyStyle="{ textAlign: 'left' }" :placement="'top'" :closable="true" @close="visibleDrawer = false"
          :visible="visibleDrawer" :mask="false" :get-container="false" :wrap-style="{ position: 'absolute' }"
          :height="120">

          <a-row :gutter="24">
            <a-col class="gutter-row" :span="24">
              <p class="text-lg">
                {{ fileStatus }}
              </p>
              <a-progress :afterVisibleChange="ocultarDrawer" :stroke-color="{
                from: '#108ee9',
                to: '#87d068',
              }" :percent="percent" />
              <p>
                {{ fileStatusDetail }}
              </p>
            </a-col>
          </a-row>
        </a-drawer>

      </div>
      <div class="w-full sm:max-w-sm">
        <label for="table-search" class="sr-only">Search</label>
        <div class="relative">
          <div class="
            flex
            absolute
            inset-y-0
            left-0
            items-center
            pl-3
            pointer-events-none
          ">
            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"></path>
            </svg>
          </div>
          <a-input-search placeholder="Buscar un usuario en particular" class="rounded-full" style="width: 100%"
            @search="onSearch" v-model="filterSearch" />
        </div>
      </div>
    </header>
    <a-spin :spinning="spinner">
      <div v-if="step === 1"
        class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-4 search-complaints">
        <a-table :columns="columns" rowKey="code" :data-source="dataRows" :pagination="false" :scroll="{ x: '1220px' }">
          <div slot="name" class="title-size font-bold text-gray-500 text-center ">
            <p>Nombre del CF</p>
          </div>
          <div slot="entity" class="title-size font-bold text-gray-500">
            Entidad
          </div>
          <div slot="context" class="title-size font-bold text-gray-500">
            Contexto
          </div>
          <div slot="code" class="flex flex-row justify-items-center title-size font-bold text-gray-500">
            Código interno
          </div>
          <div slot="solicitude" class="title-size font-bold text-gray-500">
            Solicitud
          </div>
          <div slot="actions" class="title-size font-bold text-gray-500">
            Acciones
          </div>

          <div slot="actions" slot-scope="text, record" class="flex flex-row justify-items-center">
            <button class="flex justify-center text-xs mx-auto text-blue h-min" @click="solicitudeDetail($event, record)">
              <a-icon type="eye" theme="twoTone" two-tone-color="#3366cc" :style="{
                fontSize: '20px',
              }" />
            </button>

          </div>
        </a-table>
        <div class="p-4 flex justify-end">
          <a-pagination v-model="page" :page-size="pageSize" :page-size-options="pageSizeOptions" :total="count"
            show-size-changer size="large" @showSizeChange="onShowSizeChange" />
        </div>
      </div>
    </a-spin>
    <div v-if="loading" class="absolute w-full justify-center mt-7">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
// #004884
import DynamicForm from "@/components/DynamicForm/";
import moment from 'moment';
import TotalComplaints from "@/components/Dashboard/Complaints/TotalComplaints.vue";
import { io } from "socket.io-client";
const columns = [
  {
    slots: { title: "name" },
    dataIndex: "name",
    key: "name",
    width: 90,
  },
  {
    slots: { title: "entity" },
    dataIndex: "entity",
    key: "entity",
    width: 90,
  },
  {
    slots: { title: "context" },
    dataIndex: "context",
    key: "context",
    width: 70,
    ellipsis: true,
  },
  {
    slots: { title: "code" },
    dataIndex: "code",
    key: "code",
    width: 80,
  },
  {
    slots: { title: "solicitude" },
    dataIndex: "solicitude",
    key: "solicitude",
    width: 64
  },
  {
    slots: { title: "actions" },
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
    width: 34
  },
];
export default {
  components: { DynamicForm, TotalComplaints },
  data() {
    return {
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      columns: columns,
      filterSearch: "",
      step: 1,
      visibleDrawer: false,
      spinner: false,
      timeoutId: null,
      percent: 0,
      showTab: -1,
      showLimit: false,
      fileStatus: 'Enviando solicitud...',
      fileStatusDetail: '',
      socket: null,
      download_in_process: false,
      drawerInfo: [
        {
          email: 'Sebas',
          name: { last: 'Acevedo' },
          href: 'url'
        }
      ],
      dataTable: {
        header: [
          { title: "Nombre del CF", style: "w-20" },
          { title: "Entidad", style: "w-15" },
          { title: "Codigo interno", style: "w-15" },
          { title: "Contexto", style: "w-20" },
          { title: "Solicitud", style: "w-15" },
          { title: "Acciones", style: "w-15" },
        ],
        content: [],
      },
      initForm: {},
      loading: false,
      count: 0,
      page: 1,
      pageSize: 100,
      totalPages: 1,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      dataRows: [],
    };
  },
  created() {
    //this.createSocket();
    this.getData();

  },
  beforeDestroy() {
    this.stopPolling();
    if (this.socket) {
      console.log('Socket beforeDestroy')
      this.socket.disconnect();
    }
  },
  watch: {
    visibleDrawer: function (newVal, oldVal) {
      if (newVal === false) {
        this.stopPolling();
      } else {
        this.fileStatus = 'Enviando solicitud...';
        this.fileStatusDetail = ''
        this.percent = 0;
        this.startPolling();
      }
    },
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.getData();
    },
  },
  methods: {
    async dateChanged() {
      this.visibleDrawer = false;
      await this.getData();
    },
    async startPolling() {
      console.log('startPolling')
      this.pollFunction()
    },
    async pollFunction() {
      try {
        const urlService1 = `/jurisdiccional-servi/mng-service/generate-zip?date=${this.date}`;
        console.log(urlService1)
        const { error, data } = await this.$api.getResource(urlService1);
        if (error) {
          this.fileStatus = 'Error';
          this.fileStatusDetail = 'Parece que  hubo un problema al descargar el archivo';
          this.percent = 0;
          this.stopPolling();
          return;
        }
        const status = data.status;
        if (status == 'finished') {
          this.fileStatus = 'Finalizado';
          this.fileStatusDetail = `Se han generado ${data.count} archivos. La descarga comenzará automáticamente en unos momentos. Por favor, espera...`;
          this.percent = data.percent;

          let a = document.createElement("a");
          a.href = data.url;
          a.target = "_blank";
          a.download = '';

          this.stopPolling();
          a.click();

        }
        else if (status == 'in progress') {
          this.fileStatus = 'Generando archivo...';
          this.fileStatusDetail = `Progreso: ${data.processed_files}/${data.count} archivos completados.`;
          this.percent = data.percent;
          if (this.visibleDrawer) {
            this.timeoutId = setTimeout(this.pollFunction, 1122);
          }
        }
        else if (status == 'created') {
          this.fileStatus = 'Consultando...';
          this.fileStatusDetail = `${data.count} archivos encontrados.`;
          this.percent = data.percent;
          if (this.visibleDrawer) {
            this.timeoutId = setTimeout(this.pollFunction, 1122);
          }
        }
        else {
          this.fileStatus = 'Error';
          this.fileStatusDetail = 'Parece que  hubo un problema al descargar el archivo';
          this.percent = 0;
          this.stopPolling();
        }
      } catch (error) {
        console.error('Error en la petición:', error);
        this.fileStatus = 'Error';
        this.fileStatusDetail = 'Parece que  hubo un problema al descargar el archivo';
        this.percent = 0;
        this.stopPolling()
      }
    },
    stopPolling() {
      console.log('stopPolling')
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
      setTimeout(() => {
        if (this.visibleDrawer) {
          this.visibleDrawer = false;
        }
      }, 3000);
    },
    createSocket() {
      this.socket = io('https://sfc-queries-m3ilso5sxq-uc.a.run.app');

      this.socket.on("estado_archivo", (data) => {
        this.fileStatus = data.estado;
        this.percent = data.percent;
      });
      console.log('Socket creado')
    },
    iniciarProceso() {
      console.log('Socket iniciarProceso')
      try {
        console.log('Socket', this.socket)
        this.socket.emit('iniciar_proceso', {});
      } catch (error) {
        console.log('Socket error', error)
      }

    },
    ocultarDrawer(visibleDrawer) {
      this.visibleDrawer2 = visibleDrawer;
      console.log(visibleDrawer)
    },
    no_hoy_ni_despues(current_date) {
      let isSameOrAfter = current_date && moment().subtract(1, 'day').isBefore(current_date);
      return isSameOrAfter;
    },
    async handleMenuClick(e) {
      this.download_in_process = true;
      if (!this.download_in_process) {
        this.$notification.open({
          title: "Notificación",
          message: "Descarga en proceso...",
        });
        return;
      }
      await this.generateInfoSolip(e.key);
    },
    async solicitudeDetail(checked, record) {

      this.$router.push({ 'name': 'soliditude-detail', params: { id: record.code } })

    },
    onSearch(value) {
      this.$notification.open({
        title: "Búsqueda",
        message: "Buscando..." + value,
      });
      this.filterSearch = value;
      this.getData()
    },
    async getSolipFile() {
      this.loading = true;
      this.loading = false;
    },
    async getData() {
      this.loading = true;
      let data = {};

      try {
        this.spinner = true;
        const search = this.filterSearch == "" ? "" : `&search=${this.filterSearch}`;
        const urlService1 = `/jurisdiccional-servi/complaint-management?time=${this.date}&page=${this.page}&page_size=${this.pageSize}${search}`;
        let { error, data: dataPivot } = await this.$api.getResource(
          urlService1
        );
        this.spinner = false;
        if (error) {
          console.log(error);
          this.$notification.error({
            message: "Error",
            description: "Ocurrio un error en la consulta",
          });
        } else {
          data = dataPivot;
          this.dataRows = data.data.map((item) => {
            return {
              name: item.cf_name,
              entity: item.company_name,
              code: item.document_id,
              context: item.context,
              solicitude: item.application,
              actionPlans: [],
            };
          });
          this.count = data.count;
        }
      } catch (e) {
        console.log("Err in getData", e);
        this.spinner = false;
        this.$notification.error({
          message: "Error",
          description: "Ocurrio un error",
        });
      }

      this.loading = false;
    },
    async generateInfoSolip(type_file = 'xlsx') {
      this.loading = true;
      this.spinner = true;
      let { data } = await this.$api.getResource(
        `/queries-servi/file/generate_solip_report?time=${this.date}&get_file=${type_file}`
      );
      this.spinner = false;
      this.loading = false;
      const link = document.createElement('a')

      link.href = data.url
      link.setAttribute('download', 'solip_file')
      document.body.appendChild(link)

      link.click();
      await this.getData();
      this.download_in_process = false;

    },
    updateRouterQuery() {
      this.$router
        .replace({
          query: {
            page: this.page
          },
        })
        .catch((error) => error);
    },
    async onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      await this.getData();
    },
    pagination(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.page = newPage;
      }
      this.getData();
    },
    currentDate() {
      // Obtener la fecha actual
      const today = new Date();

      // Obtener los componentes de la fecha
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, por lo que se suma 1
      const day = String(today.getDate()).padStart(2, "0");

      // Crear la cadena en el formato deseado
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const files = event.target.files;

      if (files.length > 5) {
        alert("Selecciona máximo 5 archivos.");
        return;
      }

      this.files = files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 1024 * 1024) {
          alert(
            "El archivo " +
            file.name +
            " excede el tamaño máximo permitido (1 MB)."
          );
          return;
        }

        console.log("Archivo seleccionado:", file);
      }
    },
    changeShowTab(index) {
      if (this.showTab === index) {
        this.showTab = -1;
      } else {
        this.showTab = index;
      }
    },
    saveRecommendation() {
      this.step = 1;
    },
    async manage(idClaim) {
      this.loading = true;
      const urlService = `/complaint_detail/${idClaim}`;
      let { data } = await this.$api.getResource(urlService);
      if (data !== undefined) {
        this.initForm = data;
      } else {
        const urlService1 = `/complaint_historical/?codigo_queja=${idClaim}`;
        let { data: data1 } = await this.$api.getResource(urlService1);
        const urlService2 = `/complaint_historical/${idClaim}/?fecha_actualizada=${data1.actualizaciones[data1.actualizaciones.length - 1]
          .fecha_actualizada
          }`;
        let { data: data2 } = await this.$api.getResource(urlService2);
        this.initForm = data2.data;
      }
      this.loading = false;
      this.changeStep(2);
    },
    changeReports(addReport = true, index) {
      if (addReport) {
        this.newRecommendation.reports.push({});
      } else {
        let arrayPivot = [...this.newRecommendation.reports];
        arrayPivot.splice(index, 1);
        this.newRecommendation.reports = arrayPivot;
      }
    },
    changeStep(step = 1) {
      this.step = step;
    },
    saveTemporaly() {
      this.msgButtonTemp = "Guardado!";
      setTimeout(() => {
        this.visibleButtonSaveTemp = false;
      }, 2000);
      localStorage.setItem(
        "newRecommendationFormSave",
        JSON.stringify(this.dataForm)
      );
    },
    formatStatus(status, defaultClass) {
      const obj = {
        Borrador: "btn-warning-light",
        Formulado: "btn-success-light",
        Finalizado: "btn-success",
        Cerrado: "btn-danger-light",
        Seguimiento: "btn-primary-light",
      };
      return obj[status] + " " + defaultClass;
    },
    formatDate(inputDate) {
      // Convierte la cadena de entrada en un objeto Date
      const date = new Date(inputDate);

      // Obtiene los componentes de la fecha
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Añade cero al principio si es necesario
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      // Calcula la hora en formato 12 horas
      const formattedHours = hours % 12 || 12;

      // Formatea la fecha en el formato deseado en inglés
      const formattedDate = `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;

      return formattedDate;
    },
  },
};
</script>

<style scoped>
.accordion-100>div {
  width: 100%;
}

.selection-padding-10>div>div>div,
.selection-padding-10>div>input {
  padding: 10px !important;
  min-height: 52px;
}

button {
  border-radius: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.p-10 {
  padding: 10px;
}

.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ms-auto {
  margin-left: auto;
}

.text-primary {
  color: #4558dc;
}

.btn-large {
  min-width: 300px;
}

.btn-small {
  min-width: 90px;
  padding: 3px;
}

.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}

.btn-square {
  width: 40px;
  height: 40px;
}

.btn-square-small {
  width: 26px;
  height: 26px;
}

.rounded {
  border-radius: 50px;
}

div>small {
  color: #7a7a7a;
}

.rounded-border {
  cursor: pointer;
  display: flex;
  padding-top: 2px;
  padding-left: 7px;
  border-radius: 50px;
  height: 26px;
  width: 26px;
  border: 0.5px solid transparent;
  margin: 0px;
  margin-right: 8px;
}

.rounded-border:hover {
  transition: 0.5s;
  color: #4558dc;
}

.rounded-border-active {
  border: 0.5px solid #4558dc;
}

.btn-gray {
  background: #e7e7e7;
  color: black;
}

.btn-primary {
  background: #4558dc;
  color: white;
}

.btn-warning {
  background: #f8b446;
  color: white;
}

.btn-danger {
  background: #ea4a76;
  color: white;
}

.btn-success {
  background: #14a171;
  color: white;
}

.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}

.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}

.btn-primary-light {
  background: #edf3ff;
  color: #4558dc;
}

.btn-success-light {
  background: #e5fbf5;
  color: #14a171;
}

.no-border {
  border: 0px !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}

.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.search>input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}

.search>button {
  border-radius: 10px;
}

.table {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}

.tableHeader {
  padding: 10px 0;
  background: #fafafa;
  font-weight: bold;
}

.tableHeader>div>h3 {
  color: #6b6b6b !important;
}

.table>div {
  flex-wrap: wrap;
}

.table>div>div {
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 1px;
}

.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}

.progressbar>div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #14a171;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}

.d-none {
  display: none;
}

.containerFileOptional {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed gray;
  padding: 20px;
  border-radius: 15px;
}

.containerFileOptional div {
  border-radius: 15px;
  background: rgba(51, 102, 204, var(--tw-bg-opacity));
  margin-bottom: 10px;
  color: white;
  padding: 0.5em 4em;
}

.btn-save-temporaly {
  position: fixed;
  right: 40px;
  top: 200px;
  z-index: 10000;
}

.cursor {
  cursor: pointer;
}

.contentDropdown {
  position: absolute;
  background: white;
  bottom: 20px;
  border: 1px solid #e7e7e7;
  width: 57px;
}

.contentDropdown>div {
  width: 100% !important;
}

.search-complaints .ant-table-thead>tr>th {
  text-align: center;
}

.demo-dropdown-wrap :deep(.ant-dropdown-button) {
  margin-right: 8px;
  margin-bottom: 8px;
}
</style>